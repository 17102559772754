import React, { useEffect, useRef } from "react";

const FadeUpOnScroll = ({ children }) => {
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animation = `fadeUp 1s ease forwards`;
        } else {
          entry.target.style.animation = "";
        }
      });
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <div 
    ref={ref} 
    style={{ opacity: 0 }}
    >
      {children}
    </div>
  );
};

export default FadeUpOnScroll;