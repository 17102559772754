import React from 'react';
import FadeUpOnScroll from '../../../components/FadeUp';

const AboutSection4 = (props) => {

    return (
        <section className="section-4 dark-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-22">
                        <FadeUpOnScroll>
                            <div className="title-block">
                                <h1 className="h1 section-title text-white extra-spacing">{props.mainTitle}</h1>
                            </div>
                        </FadeUpOnScroll>
                        <div className="content-block">
                            <div className="row">
                                <div className="col-lg-10">
                                    <FadeUpOnScroll>
                                        <div className="image-block">
                                            <img
                                                src={props.image1x?.sourceUrl}
                                                srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                                                width={props.image1x?.width}
                                                alt={props.image1x?.altText}
                                            />
                                        </div>
                                    </FadeUpOnScroll>
                                </div>
                                <div className="col-lg-1"></div>
                                <div className="col-lg-13">
                                    <FadeUpOnScroll>
                                        <p className="section-description text-white">{props.mainDescription}</p>
                                    </FadeUpOnScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection4